

























































































import { Component, Mixins } from 'vue-property-decorator'
import Email24 from '@carbon/icons-vue/es/email/24'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import { ValidationObserver } from 'vee-validate'

import ProgressBar from '@/partials/components/ProgressBar.vue'
import DataCard from '@/partials/components/DataCard.vue'
import UserAttachments from '@/partials/tables/associations/Attachments.vue'
import UserExams from '@/partials/tables/associations/Exams.vue'
import ServiceUserDiaries from '@/partials/tables/associations/ServiceUserDiaries.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

type EvaluateType = 'accept' | 'suspend' | 'activate'

@Component({
  components: {
    ProgressBar,
    UserExams,
    DataCard,
    Email24,
    UserAttachments,
    ServiceUserDiaries,
    PasswordConfirmField,
    ValidationObserver
  }
})
export default class AssociationAttachmentsShow extends Mixins(PermissionsMixin, FlashMessageMixin) {
  modalIsOpened = false
  modalMessage = ''
  evaluateType: EvaluateType = 'accept'

  form: Record<string, any> = {
    notes: '',
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  association = {
    shortUser: {
      id: null,
      name: null,
      email: null
    },
    points: 0,
    pointsCurrent: 0
  }

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`/user/association/${this.$route.params.id}`)
      .then(response => {
        this.association = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }

  evaluate (type: EvaluateType) {
    const typeVerb = {
      accept: 'efetivar',
      suspend: 'suspender',
      activate: 'reativar'
    }

    this.evaluateType = type
    this.modalMessage = `Tem certeza que deseja ${typeVerb[type]} esse usuário?`
    this.toggleModal('modalIsOpened')
  }

  submit () {
    axios.patch(`/user/association/${this.$route.params.id}/${this.evaluateType}`, this.form)
      .then((response) => {
        const flashMessage = { message: response.data.data, isSuccess: true }
        this.$router.push({ name: 'AssociationIndex' }, () => {
          this.setFlashMessage(flashMessage)
        })
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  get associationPointsPercentage () {
    return (this.association.pointsCurrent / this.association.points) * 100
  }

  clearForm () {
    this.form = {
      notes: '',
      confirm: '',
      errors: {
        confirm: ''
      }
    }
  }
}
